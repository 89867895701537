
import { defineComponent, ref } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-widget-6",
  components: {
    Dropdown3
  },
  props: {
    widgetClasses: String
  },
  setup() {
    const list = ref([
      {
        color: "warning",
        icon: "icons/duotone/Home/Library.svg",
        title: "Group lunch celebration",
        text: "Due in 2 Days",
        number: "+28%"
      },
      {
        color: "success",
        icon: "icons/duotone/Communication/Write.svg",
        title: "Navigation optimization",
        text: "Due in 2 Days",
        number: "+50%"
      },
      {
        color: "danger",
        icon: "icons/duotone/Communication/Group-chat.svg",
        title: "Rebrand strategy planning",
        text: "Due in 5 Days",
        number: "-27%"
      },
      {
        color: "info",
        icon: "icons/duotone/Communication/Group-chat.svg",
        title: "Product goals strategy",
        text: "Due in 7 Days",
        number: "+8%"
      }
    ]);

    return {
      list
    };
  }
});
